:root {
  --max-width: 1200px;
  --border-radius: 5px;
  --bg-main: #E3EDF7;
  --bg-sub: #57bac9;
  --color-main: #484848;
  --color-sub: #fff;
  --color-tint: #08A3FF;
  --border: solid 1px rgba(0,0,0,0.05);
  --error: #E02B1C;
  --success: #40BC21;
  --warning: #F4AC5A;
  --bg-hover: #F5F7FA;
  --shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 0 70px 0 rgba(25, 90, 200, 0.15);
  --gap: 30px;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-size: 15px;
  direction: rtl;
  background-color: var(--bg-main);
  color: var(--color-main);
}
.animated {
  transition: all 0.15s ease-in-out;
}
.animated-slow {
  transition: all 0.5s ease-in-out;
}
.animated-button {
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
}
a {
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
input, button, textarea {
  font-family: var(--font-family);
  cursor: text;
  letter-spacing: -0.3px;
}

button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: var(--color-tint);
  color: var(--color-sub);
  font-weight: 700;
  cursor: pointer;
  text-align: center;
}

.hide {
  display: none!important;
}
div:not(.noflex) {
  display: flex;
  max-width: 100%;
}
div, main, nav, section, header, footer, article {
  display: flex;
  flex-direction: column;
}
section {
  width: var(--max-width);
  max-width: 100%;
  align-self: center;
}
main {
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  gap: var(--gap);
}

.click {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

nav::-webkit-scrollbar {display:none;}

p, h1, h2, h3, h4, h5, h6 {
  transform: scale(1, 1.05);
  letter-spacing: -0.5px;
}
p {
  transform: scale(1, 1.05);
  letter-spacing: -0.3px;
}

iframe {
  border: none;
}

.not-found {
  justify-content: center;
  align-items: center;
  flex: 1;
  height: calc(100vh - 164px);
  gap: 10px;

  a {
    color: var(--color-tint);
    font-weight: 500;
    display: block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: var(--color-tint);
    color: var(--color-sub);
    font-weight: 700;
  }
}
ul, ol {
  padding: 0 20px 0 0;
}
img {
  width: 100%;
  height: auto;
}
audio {
  display: none;
}
@media (max-width: 768px) {
  html, body {
    font-size: 4vw;
  }
  .hide-on-mobile {
    display: none!important;
  }
  main {
    gap: 0;
    &>section {
      padding-top: 0;
    }
  }
}
@media (min-width: 769px) {
  .hide-on-desktop {
    display: none!important;
  }
}


.fb-comments-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
.fb-comments-container iframe {
  width: 100% !important;
}
.gs-snippet, .gcsc-find-more-on-google, .gs-spelling, .gs-title {
  display: unset!important;
}
.gsc-control-cse .gsc-table-result {
  flex-direction: row;
  margin: 2px 0;
}
.gsc-thumbnail-left {
  display: none!important;
}
.gs-image {
  width: 100%!important;
  height: auto!important;
}
.gs-web-image-box {
  margin-right: 0!important;
}